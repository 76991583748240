import React, {useEffect} from 'react';
import ContentModal from '../components/content-modal';
import MailIcon from '../../assets/images/mail-icon.png';
import styled from 'styled-components/native';
import config from './config';
import TextBox from '../../components/TextBox';
import {useNavigation} from '@react-navigation/native';
import Storage from '../../storage';

const Screen = styled(ContentModal)`
  position: absolute;
  top: ${config.modals.top}px;
  right: 15px;
  width: 33vw;
`;

const StyledTextBox = styled(TextBox)`
  padding: 10px 20px;
`;

export default function WelcomeScreen() {
  const navigation = useNavigation();

  useEffect(() => {
    const checkWelcomeScreenStatus = async () => {
      const hasSeenWelcomeScreen = await Storage.hasSeenWelcomeScreen();

      if (hasSeenWelcomeScreen) {
        navigation.navigate('Map');
      }

      await Storage.saveHasSeenWelcomeScreen();
    };

    checkWelcomeScreenStatus();
  }, []);

  return (
    <Screen title="Welcome" icon={null}>
      <StyledTextBox>
        This service provides environmental conditions reports for participating 
        locations in Alabama, Florida, and South Carolina.
      </StyledTextBox>
      <StyledTextBox>
        These reports are made through volunteer observations and designed to 
        alert and inform the public of beach conditions at participating locations. 
        Please understand conditions can vary greatly from one area to another.
      </StyledTextBox>
      <StyledTextBox>
        Occasional delays in reporting may occur as many of Mote's Beach Ambassador 
        volunteers are lifeguards and other beach staff whose primary focus is on swimmer safety.
      </StyledTextBox>
    </Screen>
  );
}
